import styled from 'styled-components';
import cn from 'classnames';

import ABOUT_1 from '../../static/images/about_1.jpg';
// import ABOUT_2 from '../../static/images/about_2.png';

import ICON_1 from '../../static/images/location.svg';
import ICON_2 from '../../static/images/phone.svg';
import ICON_3 from '../../static/images/mail.svg';

export default function About() {
  return (
    <Box>
      <span className={cn('title')}>About</span>

      <div className={cn('wrap')}>
        <span className={cn('wrap-title')}>LEE & BAE</span>

        <div className={cn('wrap-contents')}>
          The 10-year-old gallery
          <br />
          LEE & BAE was founded in Haeundae, Busan, Korea in 2010.
          <br />
          Located in riverside of Suyeong with beautiful landscape and based in
          the area of 430 square meters, LEE & BAE organizes
          <br />
          exhibitions of different themes every two months.
          <br />
          Since its founding, the primary aim of the gallery has been to promote
          academic research and exhibitions of Korean
          <br />
          contemporary art.
          <br />
          Gradually, international exhibition exchange has also become one of
          the main objectives of the gallery.
          <br />
          The gallery promotes Korean established and emerging artists of
          contemporary art, thus focusing on identity of Korean
          <br />
          contemporary art and the social aspects of visual art.
          <br />
          Working with its represented and contracted artists, the gallery
          organizes curated exhibitions and plans academic exchange.
          <br />
          LEE & BAE's represented contemporary artists apply various media in
          their artworks to emphasize Korea's social and democratic
          <br />
          characteristics.
          <br />
          They document various current global views through visual art.
        </div>

        <img
          src={ABOUT_1}
          className={cn('wrap-img')}
          alt='about_1'
          draggable={false}
        />

        <span className={cn('wrap-country')}>Busan</span>

        <span className={cn('wrap-icon_wrap')}>
          <img
            className={cn('wrap-icon_wrap-icon')}
            src={ICON_1}
            alt='icon'
            style={{ width: '16px', height: 'auto' }}
            draggable={false}
          />
          127 Jwasuyeong-ro, Suyeong-gu, Busan, Korea (48214)
        </span>

        <span className={cn('wrap-icon_wrap')}>
          <img
            className={cn('wrap-icon_wrap-icon')}
            src={ICON_2}
            alt='icon'
            style={{ width: '19px', height: 'auto' }}
            draggable={false}
          />
          +82 51 756 2111
        </span>

        <span
          className={cn('wrap-icon_wrap')}
          style={{ marginBottom: '100px' }}
        >
          <img
            className={cn('wrap-icon_wrap-icon')}
            src={ICON_3}
            alt='icon'
            style={{ width: '19px', height: 'auto' }}
            draggable={false}
          />
          galleryleebaekorea@gmail.com
        </span>

        {/* <img
          src={ABOUT_2}
          className={cn('wrap-img')}
          alt='about_2'
          draggable={false}
        />

        <span className={cn('wrap-country')}>Newyork</span>

        <span className={cn('wrap-icon_wrap')} style={{ fontStyle: 'italic' }}>
          <img
            className={cn('wrap-icon_wrap-icon')}
            src={ICON_1}
            alt='icon'
            style={{ width: '16px', height: 'auto' }}
            draggable={false}
          />
          coming soon
        </span>

        <span className={cn('wrap-icon_wrap')} style={{ fontStyle: 'italic' }}>
          <img
            className={cn('wrap-icon_wrap-icon')}
            src={ICON_2}
            alt='icon'
            style={{ width: '19px', height: 'auto' }}
            draggable={false}
          />
          coming soon
        </span>

        <span className={cn('wrap-icon_wrap')} style={{ fontStyle: 'italic' }}>
          <img
            className={cn('wrap-icon_wrap-icon')}
            src={ICON_3}
            alt='icon'
            style={{ width: '19px', height: 'auto' }}
            draggable={false}
          />
          coming soon
        </span> */}
      </div>
    </Box>
  );
}

const Box = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  padding: 100px 150px;

  .title {
    width: 100%;
    height: fit-content;
    font-family: 'Noto Serif';
    font-size: 26px;
    font-weight: 400;
    padding-bottom: 20px;
    border-bottom: 1px solid #ddd;
    user-select: none;
  }

  .wrap {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding: 50px 20px;

    &-title {
      font-size: 40px;
      font-weight: 600;
      margin-bottom: 20px;
    }

    &-contents {
      line-height: 2;
      margin-bottom: 100px;
    }

    &-img {
      width: 100%;
      height: fit-content;
      margin-bottom: 30px;
    }

    &-country {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 30px;
    }

    &-icon_wrap {
      width: fit-content;
      height: fit-content;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;
      font-size: 16px;
      margin-bottom: 20px;

      &-icon {
        margin-right: 20px;
      }
    }
  }
`;
